import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  name: Yup.string().min(1, 'Minimum 1 letters').required('Required'),
  sortOrder: Yup.number().min(0),
  description: Yup.string().min(5, 'Minimum 5 letters').required('Required'),
  course: Yup.string().min(5, 'Minimum 5 letters').required('Required'),
  usersAllowed: Yup.array().of(
    Yup.string().email('Please enter a valid email address')
  ),
})

export default validationSchema
