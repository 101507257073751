import 'react-quill/dist/quill.snow.css'

import React, { useState } from 'react'
import ReactQuill from 'react-quill'

const TextEditor = ({onFormDataChange, formEditData}) => {
  const [value, setValue] = useState(formEditData?.content || '')
    function changeHandler(text) {
        setValue(text);
        onFormDataChange({content: text})
        console.log(text)
    }
    const modules = {
        toolbar: [
          [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
          [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
          [{size: []}],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{'list': 'ordered'}, {'list': 'bullet'}, 
           {'indent': '-1'}, {'indent': '+1'}],
          ['link', 'image', 'video'],
          ['clean']
        ],
        clipboard: {
          // toggle to add extra line breaks when pasting HTML:
          matchVisual: false,
        }
      }
  return (
    <>
      <ReactQuill style={{height: '250px', marginBottom: '75px'}} modules={modules} theme="snow" value={value} onChange={changeHandler} />
    </>
  )
}

export default TextEditor
