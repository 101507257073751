import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography,
} from '@material-ui/core'
import { Formik } from 'formik'
import { Helmet } from 'react-helmet'
import { useDispatch } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import useToken from 'src/hooks/useToken'
import { SignInSuccessful } from 'src/redux/actions'
import { setError } from 'src/redux/errorReducer'
import API from 'src/util/api'
import * as Yup from 'yup'

const Login = () => {
  const dispatch = useDispatch()
  const [_token, setToken] = useToken()
  const onSubmit = loginInfo => {
    API.post('newlogin', loginInfo)
      .then(data => {
        if (data) {
          setToken(data?.token)
          dispatch(SignInSuccessful(data))
        }
      })
      .catch(error => {
        dispatch(setError(error))
      })

    // dispatch(SignIn(values))
  }

  return (
    <>
      <Helmet>
        <title>Login | Langistan</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required'),
              password: Yup.string().max(255).required('Password is required'),
            })}
            onSubmit={onSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                </Box>
                <Typography color="textSecondary" variant="body1">
                  Don&apos;t have an account?{' '}
                  <Link
                    component={RouterLink}
                    to="/register"
                    variant="h6"
                    underline="hover"
                  >
                    Sign up
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  )
}

export default Login
