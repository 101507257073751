import { Box } from '@material-ui/core'
import { useEffect, useState } from 'react'

import TextEditor from '../../shared/TextEditor';

const ReadingText = ({ onDataChange, childData }) => {
  const [values, setValues] = useState({})

  useEffect(() => {
    setValues(childData)
  }, [childData])

  return (
    <Box sx={{ p: 2, border: '1px solid grey' }}>
      <TextEditor formEditData={childData} onFormDataChange={onDataChange} />
    </Box>
  )
}

export default ReadingText;
