const ExcerciseTypes = {
  LISTEN_WITH_IMAGE: 'LISTEN WITH IMAGE',
  LISTEN_WITH_AUDIO: 'LISTEN WITH AUDIO',
  LISTEN_WITH_TEXT: 'LISTEN WITH TEXT',
  LISTEN_WITH_ORDER: 'LISTEN WITH ORDER',
  LISTEN_WITH_TYPE: 'LISTEN WITH TYPE',
  LISTEN_WITH_RECORD: 'LISTEN WITH RECORD',
  READING_WITH_TEXT: 'READING WITH TEXT',
  WATCH_WITH_AUDIO: 'WATCH WITH AUDIO',
  WATCH_WITH_TEXT: 'WATCH WITH TEXT',
  WATCH_WITH_ORDER: 'WATCH WITH ORDER',
  WATCH_WITH_TYPE: 'WATCH WITH TYPE',
  WATCH_WITH_RECORD: 'WATCH WITH RECORD',
  WATCH_VIDEO: 'WATCH VIDEO',
  WATCH_4LVIDEO: 'WATCH 4LVIDEO',
}

const StatusList = [
  { key: 'true', value: 'Active' },
  { key: 'false', value: 'InActive' },
]
const IssueTypeList = [
  { key: 'content', value: 'Content Issue' },
  { key: 'technical', value: 'Technical' },
  { key: 'other', value: 'Other Issue' },
  { key: 'suggestion', value: 'Any suggestion' },
]
const IssueTypeStatusList = [
  { key: 'open', value: 'Open' },
  { key: 'pending', value: 'Pending' },
  { key: 'closed', value: 'Closed' },
]

const SurNameList = [
  { key: 'Mr.', value: 'Mr.' },
  { key: 'Miss', value: 'Mis.' },
]

const NotificationCategoryList = [
  { key: 'contentUpdate', value: 'Content Update' },
  { key: 'officialNews', value: 'Official News' },
  { key: 'technicalUpdate', value: 'Technical Update' },
]
const NotificationTargetGroupList = [
  { key: 'all_Members', value: 'All Members' },
  { key: 'hindi_Students_Only', value: 'Hindi Students Only' },
  { key: 'free_Students_Only', value: 'Free Students Only' },
  { key: 'paid_Students_Only', value: 'Paid Students Only' },
]
const NotificationTagGroupList = [
  { key: 'beginners', value: 'Beginners' },
  { key: 'intermediate', value: 'Intermediate' },
  { key: 'advanced', value: 'Advanced' },
  { key: 'fluent', value: 'Fluent' },
]

const FeedbackStatusList = [
  { key: 'submitted', value: 'Submitted' },
  { key: 'approved', value: 'Approved' },
  { key: 'rejected', value: 'Rejected' },
]
const FlexAlignment = [
  { key: 'flex-start', value: 'Left' },
  { key: 'center', value: 'center' },
  { key: 'flex-end', value: 'Right' },
]
const TextAlignment = [
  { key: 'left', value: 'Left' },
  { key: 'center', value: 'center' },
  { key: 'right', value: 'Right' },
]
export {
  ExcerciseTypes,
  FeedbackStatusList,
  FlexAlignment,
  IssueTypeList,
  IssueTypeStatusList,
  NotificationCategoryList,
  NotificationTagGroupList,
  NotificationTargetGroupList,
  StatusList,
  SurNameList,
  TextAlignment,
}
