import { ExcerciseTypes } from 'src/util/constants'

export const countries = [
  {
    value: 'india',
    key: 'India',
  },
  {
    value: 'nepal',
    key: 'Nepal',
  },
  {
    value: 'china',
    key: 'China',
  },
  {
    value: 'america',
    key: 'America',
  },
]

export const states = [
  {
    value: 'up',
    key: 'UP',
  },
  {
    value: 'mp',
    key: 'MP',
  },
  {
    value: 'uk',
    key: 'UK',
  },
]

export const surnames = [
  {
    value: 'Mr.',
    key: 'Mr.',
  },
  {
    value: 'Ms.',
    key: 'Ms.',
  },
]

export const exerciseOptionTypes = [
  {
    value: 'Listen and choose correct image',
    key: ExcerciseTypes.LISTEN_WITH_IMAGE,
  },
  {
    value: 'Listen and choose right audio',
    key: ExcerciseTypes.LISTEN_WITH_AUDIO,
  },
  {
    value: 'Listen and choose right text',
    key: ExcerciseTypes.LISTEN_WITH_TEXT,
  },
  {
    value: 'Listen and choose the right order of the text ',
    key: ExcerciseTypes.LISTEN_WITH_ORDER,
  },
  {
    value: 'Listen and type ',
    key: ExcerciseTypes.LISTEN_WITH_TYPE,
  },
  {
    value: 'Listen and record',
    key: ExcerciseTypes.LISTEN_WITH_RECORD,
  },
  {
    value: 'Watch and choose the right audio option',
    key: ExcerciseTypes.WATCH_WITH_AUDIO,
  },
  {
    value: 'Watch and choose the right text option',
    key: ExcerciseTypes.WATCH_WITH_TEXT,
  },
  {
    value: 'Watch and record',
    key: ExcerciseTypes.WATCH_WITH_RECORD,
  },
  {
    value: 'Watch and choose the order of the text',
    key: ExcerciseTypes.WATCH_WITH_ORDER,
  },
  {
    value: 'Watch Video/4L',
    key: ExcerciseTypes.WATCH_4LVIDEO,
  },
  {
    value: 'Watch Video',
    key: ExcerciseTypes.WATCH_VIDEO,
  },
  {
    value: 'Watch and type',
    key: ExcerciseTypes.WATCH_WITH_TYPE,
  },
  {
    value: 'Text Reading',
    key: ExcerciseTypes.READING_WITH_TEXT,
  },
]

export const exerciseCategories = [
  {
    key: 'Listening',
    value: 'Listening',
  },
  {
    key: 'Reading',
    value: 'Reading',
  },
  {
    key: 'Writing',
    value: 'Writing',
  },
  {
    key: 'Speaking',
    value: 'Speaking',
  },
]

export const courseCategories = [
  {
    key: 'Language',
    value: 'Language',
  },
  {
    key: 'Design',
    value: 'Design',
  },
  {
    key: 'Development',
    value: 'Development',
  },
  {
    key: 'Marketing',
    value: 'Marketing',
  },
  {
    key: 'Music',
    value: 'Music',
  },
  {
    key: 'Other',
    value: 'Other',
  },
]

export const courseDifficultyLevel = [
  {
    key: 'allLevels',
    value: 'All levels',
  },
  {
    key: 'absoluteBeginners',
    value: 'Absolute Beginners',
  },
  {
    key: 'beginner',
    value: 'Beginner',
  },
  {
    key: 'intermidiate',
    value: 'Intermidiate',
  },
  {
    key: 'advanced',
    value: 'Advanced Level',
  },
  {
    key: 'fluent',
    value: 'Fluent Level',
  },
  {
    key: 'interestingStuff',
    value: 'Interesting Stuff',
  },
]
