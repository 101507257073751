import axios from 'axios'

import { API_URI } from './config'
import LocalStorageService from './localStorageService'

axios.defaults.withCredentials = true

const axiosInstance = axios.create({
  baseURL: API_URI,
})

// Add a request interceptor
axiosInstance.interceptors.request.use(
  config => {
    // document.body.classList.add('loading-indicator')
    const token = LocalStorageService.getAuthToken()
    config.headers['Content-Type'] = 'application/json'
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    return config
  },

  error => {
    // document.body.classList.remove('loading-indicator')
    Promise.reject(error)
  }
)

let retry = false

//Add a response interceptor
axiosInstance.interceptors.response.use(
  response => {
    // document.body.classList.remove('loading-indicator')
    if (response?.data?.results?.data) {
      return response.data.results.data
    } else if (response?.data?.error) {
      return Promise.reject(response.data.message)
    } else if (response?.data?.results?.docs) {
      return response.data.results
    }
    return response.data
  },
  function (error) {
    console.log(error)
    // document.body.classList.remove('loading-indicator')
    const originalRequest = error.config
    if (retry) return

    if (error.response.status === 401) {
      originalRequest._retry = true
      retry = true
      // errorHandler(error.response.status )
      // alert('wrong creds')
      return axiosInstance.post('/refreshToken').then(res => {
        if (res.status === 200) {
          LocalStorageService.setToken(res.data)
          axiosInstance.defaults.headers.common['Authorization'] =
            'Bearer ' + LocalStorageService.getAuthToken()
          return axios(originalRequest)
        }
      })
    }

    return Promise.reject(error)
  }
)

export default axiosInstance
